import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import SEO from '../../components/seo';
import Footer from '../../sections/Footer';
import Wrap from '../../components/Wrap';

const NotFoundPage = () => {
  const [aosInit, setAosInit] = useState(false);

  useEffect(() => {
    if (!aosInit) {
      AOS.init({
        duration: 600,
        easing: 'ease-in-sine',
      });
      setAosInit(true);
    } else {
      AOS.refreshHard();
    }
  });
  return (
    <>
      <SEO title="Terms of service" />
      <Wrap>
        <h2>Firstversionist</h2>
        <h1>Terms</h1>
        <p>
          Firstversionist operates globally with its products, and for this reason the Terms of Service for our products
          are available in English on their respective websites. With regards to the services we offer, we have General
          Terms and Conditions that apply. They are solely available in Dutch as{' '}
          <a href="/terms/general-terms" rel="nofollow">Algemene Voorwaarden</a>.
        </p>

        <h3>Simplerinvoicing</h3>
        <p>
          Our Simplerinvoicing address is <code>NL:KVK 74263404</code>.<br /> Find more info on our{' '}
          <a href="https://www.moneybird.nl/simplerinvoicing/factuur-ontvangen/?id=WyJGaXJzdHZlcnNpb25pc3QgQi5WLiIsIk5MOktWSyIsIjc0MjYzNDA0IiwibmwiXQ">
            Simplerinvoicing page
          </a>
          .
        </p>
      </Wrap>
      <Footer />
    </>
  );
};
export default NotFoundPage;
